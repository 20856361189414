<template>
  <div class="Part" >
    <div class="input">
      <el-button type="primary" size="mini" @click="add" v-if="authe.Hotel_RegionalDivision_Network_AddPackage">添加套餐</el-button>
      <el-button type="primary" size="mini" @click="plan" v-if="authe.Hotel_RegionalDivision_Network_PackageManagement">套餐管理</el-button>
      <el-button type="primary" size="mini" @click="disconut" v-if="authe.Hotel_RegionalDivision_Network_DiscountManagement">优惠管理</el-button>
      <!--  筛选框 -->
      <el-form inline label-width="70px">
        <el-form-item label="账户状态">
          <el-select v-model="scFrom.status" size="mini" style="width:125px" @change="scMethod">
            <el-option label="已启用" value="yes"></el-option>
            <el-option label="已停用" value="no"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户姓名">
          <el-input v-model="scFrom.userName" @input="scMethod" clearable size="mini" style="width:125px"></el-input>
        </el-form-item>
        <el-form-item label="认证类型">
          <el-select v-model="scFrom.type" size="mini" style="width:125px" @change="scMethod">
            <el-option label="不限" value="any"></el-option>
            <el-option label="WEB-账号" value="web"></el-option>
            <el-option label="PPPoE" value="pppoe"></el-option>
            <el-option label="PPTP" value="pptp"></el-option>
            <el-option label="L2TP" value="l2tp"></el-option>
            <el-option label="OpenVPN" value="ovpn"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="scFrom.refresh" size="mini" style="width:125px">
            <el-option label="手动刷新" value="手动刷新">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="refresh" style="padding: 5px 15px !important">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格数据 -->
    <div style="text-align:center;">
      <el-table
        :data="tableData"
        border
        :height="tableHeight"
        highlight-current-row
        @row-dblclick="dbTable"
      >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="jbaUsername" label="账号" align="center"></el-table-column>
        <el-table-column prop="popName" label="用户姓名" align="center"></el-table-column>
        <el-table-column prop="jbaPppType" label="认证类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.jbaPppType | PppTypeFilter}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="packagesName" label="当前套餐" align="center"></el-table-column>
        <el-table-column prop="jbaExpires" show-overflow-tooltip label="到期时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.jbaExpires | dateFilter}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ikDeviceNickname" label="设备" align="center"></el-table-column>
        <el-table-column prop="jbaLastConntime" show-overflow-tooltip label="最近上线时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.jbaLastConntime | dateFilter}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="jbaComment" label="备注" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="jbaEnabled" label="启用状态" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.jbaEnabled | statusFilter2}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="jbaOnlineState" label="在线状态" align="center"></el-table-column>
        <!-- <el-table-column label="操作" align="center" min-width="130px">
          <template slot-scope="scope">
            <el-button size="mini" @click="handlePay(scope.$index, scope.row)">业务办理</el-button>
            <el-button size="mini" @click="handleModify(scope.$index, scope.row)">修改</el-button>
            <el-button
              size="mini"
              @click="handleDisable(scope.$index, scope.row)"
            >{{scope.row.jbaEnabled | statusFilter1}}</el-button>
          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange($event,'pageSize',getData,'clickrow')"
        @current-change="currentChange($event,'currentPage',getData,'clickrow')"
      ></el-pagination>
    </div>

    <!-- 添加 修改 详细 -->
    <el-dialog
      :title="addFrom.title"
      :visible.sync="addDia"
      width="880px"
      :close-on-click-modal="false"
       v-loading="loading"
      @close="addDiaClose"
    >
      <el-form ref="add" :model="addFrom" :rules="addFromRules" label-width="75px" size="mini">
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="addFrom.name" :readonly="detail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电话" prop="phone">
              <el-input v-model.number="addFrom.phone"  maxlength="11" show-word-limit @input="isDefaultAcc" :readonly="detail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="addFrom.remark" :readonly="detail"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="性别" prop="gender">
              <el-select v-model="addFrom.gender" :disabled="detail">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="证件类型" prop="idType">
              <el-select v-model="addFrom.idType" :disabled="detail">
                <el-option label="身份证/临时身份证/户口本" value="身份证/临时身份证/户口本"></el-option>
                <el-option label="回乡证" value="回乡证"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" prop="idNumber">
              <el-input v-model="capital" :readonly="detail || addFrom.idType==''"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="民族">
              <el-select v-model="addFrom.nation" :disabled="detail">
                <el-option label="汉族" value="汉"></el-option>
                <el-option label="壮族" value="壮"></el-option>
                <el-option label="满族" value="满"></el-option>
                <el-option label="回族" value="回"></el-option>
                <el-option label="苗族" value="苗"></el-option>
                <el-option label="维吾尔族" value="维吾尔"></el-option>
                <el-option label="土家族" value="土家"></el-option>
                <el-option label="彝族" value="彝"></el-option>
                <el-option label="蒙古族" value="蒙古"></el-option>
                <el-option label="藏族" value="藏"></el-option>
                <el-option label="布依族" value="布依"></el-option>
                <el-option label="侗族" value="侗"></el-option>
                <el-option label="瑶族" value="瑶"></el-option>
                <el-option label="朝鲜族" value="朝鲜"></el-option>
                <el-option label="白族" value="白"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生日">
              <el-date-picker
              :picker-options="pickerOptions"
                style="width:100%"
                v-model="addFrom.birthday"
                format="yyyy年MM月dd日"
                value-format="yyyy年MM月dd日"
                type="date"
                placeholder="选择日期"
                :readonly="detail"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件地址" prop="address">
              <el-input v-model="addFrom.address" :readonly="detail"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="认证类型">
              <el-select v-model="addFrom.certificationType" :disabled="detail">
                <el-option label="不限" value="any"></el-option>
                <el-option label="WEB-账户" value="web"></el-option>
                <el-option label="PPPoE" value="pppoe"></el-option>
                <el-option label="PPtP" value="pptp"></el-option>
                <el-option label="L2TP" value="l2tp"></el-option>
                <el-option label="OpneVPN" value="ovpn"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="套餐类型" prop="planType">
              <el-select
                v-model="addFrom.planType"
                @change="planTypeChange($event,'addFrom')"
                :disabled="detail || modityCk"
              >
                <el-option
                  v-for="item in addFrom.packAgetOpt"
                  :key="item.sppId"
                  :label="item.sppPackName"
                  :value="item.sppId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="上行速率">
              <el-input v-model="addFrom.upload" readonly>
                <template slot="append">KB/s</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="下行速率">
              <el-input v-model="addFrom.download" readonly>
                <template slot="append">KB/s</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="绑定网卡">
              <el-select v-model="addFrom.boundNetworkCard" :disabled="detail">
                <el-option label="自动" value="any"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-show="addFrom.title=='添加' || addFrom.title=='修改'">
            <el-form-item label="绑定MAC">
              <el-select v-model="addFrom.boundMAC">
                <el-option label="自动" value="1"></el-option>
                <el-option label="手动" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-show="addFrom.title=='查看用户'">
            <el-form-item label="绑定MAC">
              <el-input v-model="addFrom.jbaMac" :readonly="detail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开始时间" :readonly="detail" prop="startDate">
              <el-date-picker
              :picker-options="pickerOptions2"
                v-model="addFrom.startDate"
                style="width:100%"
                @change="startChange('addFrom')"
                type="datetime"
                :readonly="detail || modityCk"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结束时间" :readonly="detail">
              <el-date-picker v-model="addFrom.endDate" style="width:100%" type="datetime" readonly></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="IP获取方式">
              <el-select v-model="addFrom.obtainIP" @change="ipChang" :disabled="detail">
                <el-option label="自动" value="1"></el-option>
                <el-option label="手动" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="IP地址" prop="IPAdress">
              <el-input v-model.trim="addFrom.IPAdress" :readonly="addFrom.obtainIP == '1'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="设备" prop="device">
              <el-select v-model="addFrom.device" :disabled="detail || modityCk">
                <el-option
                  v-for="item in addFrom.deviceOpt"
                  :key="item.ikDeviceId"
                  :label="item.ikDeviceNickname"
                  :value="item.ikDeviceId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-show="addFrom.title != '修改'">
            <el-form-item label="优惠政策">
              <el-select
                v-model="addFrom.discount"
                :disabled="detail"
                @change="discountChnage($event,'addFrom')"
              >
                <el-option label="无" :value="(null)"></el-option>
                <el-option
                  v-for="item in addFrom.discountOpt"
                  :key="item.sfpId"
                  :label="item.sfpName"
                  :value="Number(item.sfpId)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6">
            <el-form-item label="上网账户" prop="account">
              <el-input
                v-model="addFrom.account"
                :readonly="addFrom.title!='添加' || addFrom.other.indexOf('默认') != -1"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="登录密码" prop="password">
              <el-input v-model="addFrom.password" :readonly="pswInput"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addFrom.title!='查看用户'">
            <el-form-item>
              <el-checkbox-group v-model="addFrom.other" @change="otherChange">
                <el-checkbox v-show="addFrom.title=='添加'" label="默认" name="other"></el-checkbox>
                <el-checkbox label="是否发送账户密码到用户手机？" name="other"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="最后上线">
              <el-input v-model="addFrom.lastOnline" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="6" v-if="addFrom.title=='添加'">
            <el-form-item label="应收金额">
              <el-input v-model="addFrom.moneyDeceivable" readonly>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="addFrom.title=='添加'">
            <el-form-item label="实收金额">
              <el-input v-model="addFrom.receivedMoney" readonly>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="addFrom.title=='添加'">
            <el-form-item label="收款方式" prop="InAndExp">
              <el-select v-model="addFrom.InAndExp">
                <el-option label="现钞" value="现钞"></el-option>
                <el-option label="转账" value="转账"></el-option>
                <el-option label="刷卡" value="刷卡"></el-option>
                <el-option label="支票" value="支票"></el-option>
                <el-option label="在线支付" value="在线支付"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="addFrom.title=='添加'">
            <el-form-item label="收款账户" prop="accReceivable">
              <el-cascader
                v-model="addFrom.accReceivable"
                :show-all-levels="false"
                :options="inexList"
                :props="{ expandTrigger: 'hover' }"
                clearable
                filterable
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="addFrom.title=='查看用户'">
            <el-form-item label="当前状态">
              <el-input v-model="addFrom.currentStatus" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="addFrom.title=='查看用户'">
            <el-form-item label="缴费金额">
              <el-input v-model="addFrom.paymentAmount" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addFrom.title=='查看用户'">
            <el-form-item label="添加时间">
              <el-input v-model="addFrom.addTime" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
          size="mini"
          type="primary"
          v-show="addFrom.title=='添加' || addFrom.title=='修改'"
          @click="addSave"
        >保 存</el-button>
        <el-button
          size="mini"
          type="primary"
          v-show="addFrom.title=='添加' || addFrom.title=='修改'"
          @click="startFun"
        >读取身份证</el-button>
        <el-button
          size="mini"
          type="primary"
          v-show="addFrom.title=='查看用户'"
          @click="handleModify"
        >修改</el-button>
        <el-button size="mini" type="primary" v-show="addFrom.title=='查看用户'" @click="handlePay">业务办理</el-button>
        <el-button
          size="mini"
          type="primary"
          v-show="addFrom.title=='查看用户'"
          @click="handleDisable"
        >{{this.clickrow.jbaEnabled | statusFilter1}}</el-button>
        <el-button
          size="mini"
          type="primary"
          v-show="addFrom.title=='查看用户'"
          @click="handleOffline"
        >强制下线</el-button>
        <el-button size="mini" type="primary" v-show="addFrom.title=='查看用户'" @click="history">历史记录</el-button>
        <el-button size="mini" @click="addDia = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 业务办理 -->
    <el-dialog
      title="业务办理"
      :visible.sync="payDia"
      width="900px"
       v-loading="loading2"
      :close-on-click-modal="false"
      @close="payClose"

    >
      <el-form ref="payFrom" :rules="payFromRules" :model="payFrom" label-width="85px" size="mini">
        <el-row :gutter="5">
          <el-col :span="12">
            <el-form-item label="业务类型">
              <el-select v-model="payFrom.businessType" style="width:100%" @change="businessChange">
                <el-option label="宽带续费" value="宽带续费"></el-option>
                <el-option v-show="addFrom.jbaState == 1" label="退费销户" value="退费销户"></el-option>
                <el-option v-show="addFrom.jbaState == 1" label="套餐变更" value="套餐变更"></el-option>
                <el-option v-show="addFrom.jbaState == 1" label="宽带迁移" value="宽带迁移"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务流水号">
              <el-input v-model="payFrom.businessNumber" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">  
          <el-col :span="6">
            <el-form-item label="客户姓名">
              <el-input v-model="payFrom.name" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="证件类型">
              <el-select v-model="payFrom.idType" disabled>
                <el-option label="身份证/临时身份证/户口本" value="身份证/临时身份证/户口本"></el-option>
                <el-option label="回乡证" value="回乡证"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" >
              <el-input v-model="payFrom.idNumber" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="6">
            <el-form-item label="认证类型">
              <el-select v-model="payFrom.certificationType" disabled>
                <el-option label="不限" value="any"></el-option>
                <el-option label="WEB-账户" value="web"></el-option>
                <el-option label="PPPoE" value="pppoe"></el-option>
                <el-option label="PPtP" value="pptp"></el-option>
                <el-option label="L2TP" value="l2tp"></el-option>
                <el-option label="OpneVPN" value="ovpn"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="套餐类型">
              <el-select v-model="payFrom.planTypeold" disabled>
                <el-option
                  v-for="item in addFrom.packAgetOpt"
                  :key="item.sppId"
                  :label="item.sppPackName"
                  :value="item.sppId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="上行速率">
              <el-input v-model="payFrom.uploadold" readonly>
                <template slot="append">KB/s</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="下行速率">
              <el-input v-model="payFrom.downloadold" readonly>
                <template slot="append">KB/s</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="12">
            <el-form-item label="优惠政策">
              <el-select v-model="payFrom.discountold" disabled style="width:100%">
                <el-option label="无" :value="(null)"></el-option>
                <el-option
                  v-for="item in addFrom.discountOpt"
                  :key="item.sfpId"
                  :label="item.sfpName"
                  :value="item.sfpId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开始时间">
              <el-date-picker
                v-model="payFrom.startDateold"
                style="width:100%"
                type="datetime"
                readonly
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结束时间">
              <el-date-picker
                v-model="payFrom.endDateold"
                style="width:100%"
                type="datetime"
                readonly
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label-width="0px">
              <div class="title">{{payFrom.businessType}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-checkbox-group v-model="payFrom.other">
                <el-checkbox label="是否发送信息给客户？" name="other"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 宽带续费 套餐变更 -->
        <div v-if="payFrom.businessType == '宽带续费' || payFrom.businessType == '套餐变更'">
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label="新套餐类型" prop="planType" >
                <el-select
                  v-model="payFrom.planType"
                  style="width:100%"
                  @change="planTypeChange($event,'payFrom','业务办理')"
                >
                  <el-option
                    v-for="item in addFrom.packAgetOpt"
                    :key="item.sppId"
                    :label="item.sppPackName"
                    :value="item.sppId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上行速率">
                <el-input v-model="payFrom.upload" readonly>
                  <template slot="append">KB/s</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下行速率">
                <el-input v-model="payFrom.download" readonly>
                  <template slot="append">KB/s</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label="新优惠政策">
                <el-select
                  v-model="payFrom.discount"
                  @change="discountChnage($event,'payFrom')"
                  style="width:100%"
                >
                  <el-option label="无" :value="(null)"></el-option>
                  <el-option
                    v-for="item in addFrom.discountOpt"
                    :key="item.sfpId"
                    :label="item.sfpName"
                    :value="item.sfpId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="开始时间">
                <el-date-picker
                  v-model="payFrom.startDate"
                  :picker-options="pickerOptions2"
                  :readonly="addFrom.jbaState == 1"
                  @change="startChange('payFrom')"
                  style="width:100%"
                  type="datetime"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结束时间">
                <el-date-picker
                  v-model="payFrom.endDate"
                  style="width:100%"
                  readonly
                  type="datetime"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="payFrom.businessType == '退费销户'">
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label="退费类型">
                <el-select v-model="payFrom.refund" @change="refundChange">
                  <el-option label="普通退费" value="普通退费"></el-option>
                  <el-option label="全额不退" value="全额不退"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计费截止时间" label-width="130px">
                <el-date-picker
                  v-model="payFrom.refundDate"
                  readonly
                  style="width:100%"
                  type="datetime"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="payFrom.businessType == '宽带迁移'">
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label="设备区域">
                <el-select v-model="addFrom.device" disabled style="width:100%">
                  <el-option
                    v-for="item in addFrom.deviceOpt"
                    :key="item.ikDeviceId"
                    :label="item.ikDeviceNickname"
                    :value="item.ikDeviceId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新设备区域" prop="device">
                <el-select v-model="payFrom.device" style="width:100%">
                  <el-option
                    v-for="item in payFrom.deviceOpt"
                    :key="item.ikDeviceId"
                    :label="item.ikDeviceNickname"
                    :value="item.ikDeviceId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="5">
          <el-col :span="6">
            <el-form-item :label="payFrom.moneyT1" prop="moneyDeceivable">
              <el-input
                v-model="payFrom.moneyDeceivable"
                :readonly="payFrom.businessType != '宽带迁移'"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="payFrom.moneyT2"  prop="receivedMoney">
              <el-input v-model="payFrom.receivedMoney" :readonly="payFrom.businessType != '宽带迁移'">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="payFrom.moneyT3" prop="InAndExp">
              <el-select v-model="payFrom.InAndExp">
                <el-option label="现钞" value="现钞"></el-option>
                <el-option label="转账" value="转账"></el-option>
                <el-option label="刷卡" value="刷卡"></el-option>
                <el-option label="支票" value="支票"></el-option>
                <el-option label="在线支付" value="在线支付"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="payFrom.moneyT4" prop="accReceivable">
              <el-cascader
                v-model="payFrom.accReceivable"
                :show-all-levels="false"
                :options="inexList"
                :props="{ expandTrigger: 'hover' }"
                clearable
                filterable
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="paySave">确 定</el-button>
        <el-button size="mini" @click="payDia = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 历史记录 -->
    <el-dialog title="历史记录" :visible.sync="hisDia" width="870px" :close-on-click-modal="false">
      <div style="text-align:center;">
        <el-table :data="historyData" border height="400px" highlight-current-row>
          <el-table-column prop="suStaffName" width="160px" label="操作用户" align="center"></el-table-column>
          <el-table-column prop="jhfFollowTime" width="160px" label="操作时间" align="center"></el-table-column>
          <el-table-column prop="jhfFollowRemark" label="备注" align="center"></el-table-column>
        </el-table>
        <el-pagination
          :total="total2"
          :page-size="pageSize2"
          :page-sizes="[5,10,20,40]"
          :current-page="currentPage2"
          layout="total, sizes, prev, pager, next, jumper"
          background
          @size-change="sizeChange($event,'pageSize2',getData,'clickrow2')"
          @current-change="currentChange($event,'currentPage2',getData,'clickrow2')"
        ></el-pagination>
      </div>
      <div slot="footer">
        <el-button size="mini" @click="hisDia = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dateFtt, DateAdd, DateMinus } from "../../assets/utils";
import {
  ZK,
  getBrowserType,
  issOnlineUrl,
  browserFlag,
  setting,
  setCertificateData,
  msgType,
  openMessage,
  closeMessage,
  strlen,
  messageBox,
  Device,
  createISSonlineDevic
} from "../../assets/baseISSOnline";
import { mapState } from 'vuex'
export default {
  name: "network",
  data() {
    //电话
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      if (!Number.isInteger(+value)) {
        callback(new Error('请输入数字值'))
      } else {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('电话号码格式不正确'))
        }
      }
      
    }
    //身份证
    let checkIdCard = (rule,value,callback) => {
      if (!value) {
        return callback(new Error('号码不能为空'))
      }
      if (this.addFrom.idType == "身份证/临时身份证/户口本") {
        // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X 
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('身份证号码不正确'))
        }
      }
      if (this.addFrom.idType == "回乡证") {
        let reg = /[0-9a-zA-Z]+/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('回乡证只允许数字和字母'))
        }
      }
      if (this.addFrom.idType == "其他") {
        let reg = /^[\u4e00-\u9fa5]+$/
        if (!reg.test(value)) {
          callback()
        } else {
          callback(new Error('不能输入中文'))
        }
      }
      
    }
    //ip地址
    let checkIpAddress = (rule,value,callback) => {
      if (this.addFrom.obtainIP == "1") {
        callback()
      }else{
        if (!value) {
          return callback(new Error('ip不能为空'))
        }
         let regu = /^ +| +$/g;
         let reg = /((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)/
         if (reg.test(value)) {
            callback()
          } else if(!regu.test(value)) {
            callback(new Error('ip之间不能用有空格'))
          }
          else{
            callback(new Error('ip格式为1.1.1.1'))
          }
      }
      
    }
    //账户密码验证（不能输入中文）
    let checkAcc = (rule,value,callback) => {
      if (!value) {
        return callback(new Error('账户或密码不能为空'))
      }
      let reg = /^[\u4e00-\u9fa5]+$/
      if (!reg.test(value)) {
        callback()
      } else {
        callback(new Error('不能输入中文'))
      }
    }
    let checkMoney = (rule,value,callback)=> {
      let reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
      if (!value) {
        return callback(new Error('金额不能为空'))
      }
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的金额'))
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      addDia: false,
      detailDia: false,
      hisDia: false,
      loading: false,
      loading2: false,

      scFrom: {
        status: "",
        userName: "",
        type: "",
        refresh: "手动刷新"
      },
      addFromrefs:["add"],
      addFrom: {
        title: "",

        name: "",
        phone: "",
        remark: "",
        gender: "",
        idType: "",
        idNumber: "",
        nation: "",
        birthday: "",
        address: "",
        certificationType: "any",
        planType: "",
        upload: "",
        download: "",
        boundNetworkCard: "any",
        boundMAC: "1",
        startDate: null,
        endDate: null,
        obtainIP: "1",
        IPAdress: "",
        device: "",
        discount: null,
        account: "",
        password: "123456",
        other: ["默认","是否发送账户密码到用户手机？"],
        moneyDeceivable: "0.00",
        receivedMoney: "0.00",
        InAndExp: "",
        accReceivable: "",

        lastOnline: "",
        currentStatus: "",
        paymentAmount: "",
        addTime: "",
        jbaMac: "",
        jbaState: "",

        packAgetOpt: [],
        deviceOpt: [],
        discountOpt: []
      },
      addFromRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone:[
          { validator: checkPhone , trigger: 'blur' },
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' },
        ],
        idType: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        idNumber: [
          { validator: checkIdCard , trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入证件地址', trigger: 'blur' },
        ],
        planType: [
          { required: true, message: '请选择套餐类型', trigger: 'change' }
        ],
        startDate: [
          { required: true, message: '请选择时间', trigger: 'blur' }
        ],
        device: [
          { required: true, message: '请选择设备', trigger: 'change' }
        ],
        IPAdress: [
          { validator: checkIpAddress , trigger: 'blur' },
        ],
        account: [
          { validator: checkAcc , trigger: 'blur' },
        ],
        password: [
          { validator: checkAcc , trigger: 'blur' },
        ],
        InAndExp: [
          { required: true, message: '请选择收款方式', trigger: 'change' }
        ],
        accReceivable: [
          { required: true, message: '请选择收款账户', trigger: 'change' }
        ]
      },
      inexList: [],
      tableData: [],
      tableHeight: window.innerHeight - 240,
      clickrow: "",

      // 首页分页
      total: 0,
      pageSize: 10,
      currentPage: 1,

      //历史记录
      historyData: [],
      clickrow2: "",
      // 跟进分页
      total2: 0,
      pageSize2: 10,
      currentPage2: 1,

      //业务办理
      payDia: false,
      payFromRef:["payFrom"],
      payFrom: {
        businessType: "宽带续费",
        businessNumber: "",
        name: "",
        idType: "",
        idNumber: "",
        certificationType: "",

        planTypeold: "",
        uploadold: "",
        downloadold: "",
        discountold: "",
        startDateold: "",
        endDateold: "",

        other: [],

        planType: "",
        upload: "",
        download: "",
        discount: null,
        startDate: new Date(),
        endDate: "",

        moneyDeceivable: "0.00",
        receivedMoney: "0.00",
        InAndExp: "",
        accReceivable: "",

        refund: "普通退费",
        refundDate: new Date(),

        device: "",
        deviceOpt: [],

        moneyT1: "应收金额",
        moneyT2: "实收金额",
        moneyT3: "收款方式",
        moneyT4: "收款账户"
      },
      payFromRules: {
        planType: [
          { required: true, message: '请选择套餐', trigger: 'change' },
        ],
        moneyDeceivable: [
          { validator: checkMoney , trigger: 'blur' }
        ],
        receivedMoney: [
          { validator: checkMoney , trigger: 'blur' }
        ],
        InAndExp: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        accReceivable: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        device: [
          { required: true, message: '请选择设备', trigger: 'change' },
        ]
      }
    };
  },
  computed: {
    ...mapState(['authe','pageAuth']),
    detail() {
      return this.addFrom.title == "查看用户";
    },
    pswInput(){
      return this.addFrom.other.indexOf('默认') != -1 || this.addFrom.title == "查看用户"
    },
    modityCk(){
      return this.addFrom.title == "修改";
    },
    capital:{
      get: function(){
        return this.addFrom.idNumber
      },
      set : function(val){
          this.addFrom.idNumber = val.toUpperCase();
      }
    }
  },
  watch:{
    'addFrom.startDate': function(val){
      if (val == null) {
        this.addFrom.endDate = null
      }
    },
    'payFrom.startDate': function(val){
      if (val == null) {
        this.payFrom.endDate = null
      }
    }
  },
  created() {
    if (this.pageAuth['/network']) {
      this.getAccount();
      this.getData();
    } else {
      this.$router.replace('/notfind')
    }
  },
  methods: {
    scMethod() {
      this.getData();
    },
    dealDisabledDate(time) {
      var times = Date.now()
      return time.getTime() > times;
    },

    async add() {
      this.addFrom.title = "添加";
      this.addFrom.packAgetOpt = await this.getPackagePlan();
      this.addFrom.deviceOpt = await this.getDev();
      this.addDia = true;
    },
    //套餐管理
    plan() {
      this.$router.push('/package');
    },
    //优惠管理
    disconut() {
      this.$router.push("/discount");
    },
    //添加保存
    addSave() {
      if (this.submitForm(this.addFromrefs, this)) {
        this.loading = true
        let flag = this.addFrom.title == "添加" ? true : false;
        this.$axios({
          url: flag
            ? "/common2/broadbandAccounts"
            : `/common2/broadbandAccounts/${this.clickrow.jbaId}`,
          method: flag ? "post" : "put",
          data: {
            popName: this.addFrom.name, //
            popTelephone: this.addFrom.phone, //
            jbaComment: this.addFrom.remark, //
            popSex: this.addFrom.gender, //性别
            popIdcardType: this.addFrom.idType, //证件类型
            popIdcard: this.addFrom.idNumber,
            popNation: this.addFrom.nation, // 民族
            popBirth: this.addFrom.birthday, // 生日
            popIdcardAddress: this.addFrom.address,
            jbaPppType: this.addFrom.certificationType,
            jbaPackages: Number(this.addFrom.planType),
            jbaUpload: Number(this.addFrom.upload),
            jbaDownload: Number(this.addFrom.download),
            jbaBindIfname: this.addFrom.boundNetworkCard,
            jbaAutoMac: Number(this.addFrom.boundMAC),
            jbaStartTime: Math.round(this.addFrom.startDate / 1000).toString(),
            jbaExpires: Math.round(this.addFrom.endDate / 1000).toString(),
            //ip获取方式
            jbaIpAddr: this.addFrom.IPAdress,
            jbaGwid: this.addFrom.device,
            jbaSfpId: flag ? this.addFrom.discount : null, //优惠政策
            jbaUsername: this.addFrom.account,
            jbaPasswd: this.addFrom.password.toString(),
            notificationSMS:
              this.addFrom.other.indexOf("是否发送账户密码到用户手机？") == -1
                ? "1"
                : "0",
            receivableAmount: flag ? this.addFrom.moneyDeceivable : null, //应收金额
            actualAmount: flag ? this.addFrom.receivedMoney : null, //实际金额
            paymentMethod: flag ? this.addFrom.InAndExp : null, //收款方式
            faId: flag ? Number(this.addFrom.accReceivable[2]) : null, //收款账户
            jbaCreateTime: Math.round(this.addFrom.startDate / 1000).toString(),
            jbaMac: ""

            // jbaLastConntime: "",//最近上线时间
            // jbaEnabled: "yes",
            // jbaAutoVlanid: 0,
            // jbaBindVlanid: 0,
          }
        }).then(res => {
          this.loading = false
          let message = flag ? "添加成功" : "修改成功";
          this.$message.success(message);
          this.addDia = false;
          this.getData();
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        });
      }
      
    },
    addDiaClose() {
      this.addFrom = {
        title: "",

        name: "",
        phone: "",
        remark: "",
        gender: "",
        idType: "",
        idNumber: "",
        nation: "",
        birthday: "",
        address: "",
        certificationType: "any",
        planType: "",
        upload: "",
        download: "",
        boundNetworkCard: "any",
        boundMAC: "1",
        startDate: null,
        endDate: null,
        obtainIP: "1",
        IPAdress: "",
        device: "",
        discount: null,
        account: "",
        password: "123456",
        other: ["默认","是否发送账户密码到用户手机？"],
        moneyDeceivable: "0.00",
        receivedMoney: "0.00",
        InAndExp: "",
        accReceivable: "",

        lastOnline: "",
        currentStatus: "",
        paymentAmount: "",
        addTime: "",
        jbaMac: "",
        jbaState: "",

        packAgetOpt: [],
        deviceOpt: [],
        discountOpt: []
      };
      this.resetForm(this.addFromrefs, this)
    },
    //套餐类型事件
    async planTypeChange(val, name, type) {
      if (this.payFrom.businessType == "套餐变更") {
        for (const item of this.addFrom.packAgetOpt) {
          if (item.sppId == val) {
            this.addFrom.discountOpt = await this.getDiscount(item.sppId);
            this.$data[name].upload = item.sppUpSpeed;
            this.$data[name].download = item.sppDownSpeed;
            if (item.sppCycleType == "天") {
              this.$data[name].endDate = DateAdd(
                "d",
                Number(item.sppPackTime),
                new Date()
              ).getTime()- 1000;
            } else if (item.sppCycleType == "月") {
              this.$data[name].endDate = DateAdd(
                "m",
                Number(item.sppPackTime),
                new Date()
              ).getTime() - 1000;
            } else {
              this.$data[name].endDate = DateAdd(
                "h",
                Number(item.sppPackTime),
                new Date()
              ).getTime();
            }
            let oldAllMoney = this.clickrow.amount;
            let oldDay = DateMinus(
              this.$data[name].startDateold,
              this.$data[name].endDateold
            );
            let laveDay = DateMinus(
              new Date().getTime(),
              this.$data[name].endDateold
            );
            //算应退金额
            let money = Number(((oldAllMoney / oldDay) * laveDay).toFixed(2));
            if (money > item.sppPrice) {
              this.payFrom.moneyT1 = "应退金额";
              this.payFrom.moneyT2 = "应退金额";
              this.payFrom.moneyT3 = "退款方式";
              this.payFrom.moneyT4 = "退款账户";
              this.payFrom.moneyDeceivable = money - item.sppPrice;
            } else {
              this.payFrom.moneyT1 = "应补金额";
              this.payFrom.moneyT2 = "应补金额";
              this.payFrom.moneyT3 = "收款方式";
              this.payFrom.moneyT4 = "收款账户";
              this.payFrom.moneyDeceivable = item.sppPrice - money;
            }
            this.discountChnage(this.payFrom.discount, "payFrom");
            break;
          }
        }
      } else {
        let flag = type == "业务办理" ? true : false;
        if (flag) {
          this.$data[name].startDate = this.$data[name].startDateold;
        }
        for (const item of this.addFrom.packAgetOpt) {
          if (item.sppId == val) {
            this.addFrom.discountOpt = await this.getDiscount(item.sppId);
            this.$data[name].upload = item.sppUpSpeed;
            this.$data[name].download = item.sppDownSpeed;
            this.$data[name].moneyDeceivable = item.sppPrice;
            this.$data[name].startDate = new Date().getTime()
            if (item.sppCycleType == "天") {
              this.$data[name].endDate = flag
                ? DateAdd(
                    "d",
                    Number(item.sppPackTime),
                    new Date(this.$data[name].endDateold)
                  ).getTime()
                : DateAdd("d", Number(item.sppPackTime), new Date()).getTime();
            } else if (item.sppCycleType == "月") {

              this.$data[name].endDate = flag
                ? DateAdd(
                    "m",
                    Number(item.sppPackTime),
                    new Date(this.$data[name].endDateold)
                  ).getTime()
                : DateAdd("m", Number(item.sppPackTime), new Date()).getTime();
            } else {
              this.$data[name].endDate = flag
                ? DateAdd(
                    "h",
                    Number(item.sppPackTime),
                    new Date(this.$data[name].endDateold)
                  ).getTime()
                : DateAdd("h", Number(item.sppPackTime), new Date()).getTime();
            }
            if (flag) {
              this.discountChnage(this.payFrom.discount, "payFrom");
            } else {
              this.discountChnage(this.addFrom.discount, "addFrom");
            }

            break;
          }
        }
      }
    },
    //开始时间选择
    startChange(name) {
      for (const item of this.addFrom.packAgetOpt) {
        if (item.sppId == this.$data[name].planType) {
          if (item.sppCycleType == "天") {
            this.$data[name].endDate = DateAdd("d", Number(item.sppPackTime), new Date(this.$data[name].startDate)).getTime() - 1000;
          } else if (item.sppCycleType == "月") {
            this.$data[name].endDate = DateAdd("m", Number(item.sppPackTime), new Date(this.$data[name].startDate)).getTime() - 1000;
          } else {
            this.$data[name].endDate = DateAdd("h", Number(item.sppPackTime), new Date(this.$data[name].startDate)).getTime();
          }
          break;
        }
      }
    },
    //是否是默认
    isDefaultAcc() {
      console.log(this.addFrom.phone);
      if (this.addFrom.other.indexOf("默认") != -1) {
        this.addFrom.account = this.addFrom.phone;
      }
    },
    //ip方式改变
    ipChang(val) {
      console.log(val);
      if ((val = "1")) {
        this.addFrom.IPAdress = "";
      }
    },
    otherChange(val) {
      console.log(val);
      if (val.indexOf("默认") != -1) {
        this.addFrom.account = this.addFrom.phone;
        this.addFrom.password = 123456;
      }
    },
    //优惠
    discountChnage(val, name) {
      if (val == null) {
        this.$data[name].receivedMoney = this.$data[name].moneyDeceivable;
      } else {
        for (let item of this.addFrom.discountOpt) {
          if (item.sfpId == val) {
            console.log(item);
            if (
              Number(this.$data[name].moneyDeceivable) <
              Number(item.sfpFavorablePrice)
            ) {
              if (this.payFrom.moneyT1 == "应退金额") {
                this.$data[name].receivedMoney = this.clickrow.amount;
              } else {
                this.$data[name].receivedMoney = 0;
              }
              break;
            } else {
              if (this.payFrom.moneyT1 == "应退金额") {
                this.$data[name].receivedMoney =
                  Number(this.$data[name].moneyDeceivable) +
                  Number(item.sfpFavorablePrice);
              } else {
                this.$data[name].receivedMoney =
                  Number(this.$data[name].moneyDeceivable) -
                  Number(item.sfpFavorablePrice);
              }
              break;
            }
          }
        }
      }
    },
    //双击表格
    async dbTable(row) {
      this.addFrom.packAgetOpt = await this.getPackagePlan();
      this.addFrom.deviceOpt = await this.getDev();
      this.addFrom.discountOpt = await this.getDiscount(row.jbaPackages);
      this.clickrow = await this.getSingle(row.jbaId);
      this.addDia = true;
      this.addFrom.title = "查看用户";
      console.log(this.clickrow);

      this.addFrom.name = this.clickrow.popName;
      this.addFrom.phone = this.clickrow.popTelephone;
      this.addFrom.remark = this.clickrow.jbaComment;
      this.addFrom.gender = this.clickrow.popSex;
      this.addFrom.idType = this.clickrow.popIdcardType;
      this.addFrom.idNumber = this.clickrow.popIdcard;
      this.addFrom.nation = this.clickrow.popNation;
      this.addFrom.birthday = this.clickrow.popBirth;
      this.addFrom.address = this.clickrow.popIdcardAddress;
      this.addFrom.certificationType = this.clickrow.jbaPppType;
      this.addFrom.planType = this.clickrow.jbaPackages;
      this.addFrom.upload = this.clickrow.jbaUpload;
      this.addFrom.download = this.clickrow.jbaDownload;
      this.addFrom.boundNetworkCard = this.clickrow.jbaBindIfname;
      this.addFrom.boundMAC = this.clickrow.jbaAutoMac.toString();
      this.addFrom.jbaMac = this.clickrow.jbaMac
      this.addFrom.startDate = this.clickrow.jbaStartTime * 1000;
      this.addFrom.endDate = this.clickrow.jbaExpires * 1000;
      this.addFrom.obtainIP = this.clickrow.jbaIpAddr ? "0" : "1"
      this.addFrom.IPAdress = this.clickrow.jbaIpAddr;
      this.addFrom.device = this.clickrow.jbaGwid;
      this.addFrom.discount =
        this.clickrow.jbaSfpId == undefined
          ? null
          : Number(this.clickrow.jbaSfpId);
      console.log(this.addFrom.discount);

      this.addFrom.account = this.clickrow.jbaUsername;
      this.addFrom.password = this.clickrow.jbaPasswd;

      this.addFrom.moneyDeceivable = this.clickrow.receivableAmount;
      this.addFrom.receivedMoney = this.clickrow.actualAmount;
      this.addFrom.InAndExp = this.clickrow.paymentMethod;
      this.addFrom.accReceivable = this.clickrow.faId;
      this.addFrom.jbaState = this.clickrow.jbaState

      this.addFrom.currentStatus = this.clickrow.jbaOnlineState;
      this.addFrom.paymentAmount = this.clickrow.amount;
      this.addFrom.addTime = dateFtt(
        "yyyy-MM-dd hh:mm:ss",
        new Date(Number(this.clickrow.jbaCreateTime) * 1000)
      );
    },
    //业务办理
    handlePay() {
      this.payFrom.businessNumber = this.randomNumber();
      console.log(this.clickrow);
      this.addFrom.discountOpt = [];

      this.payFrom.name = this.clickrow.popName;
      this.payFrom.idType = this.clickrow.popIdcardType;
      this.payFrom.idNumber = this.clickrow.popIdcard;
      this.payFrom.certificationType = this.clickrow.jbaPppType;
      this.payFrom.planTypeold = this.clickrow.jbaPackages;
      this.payFrom.uploadold = this.clickrow.jbaUpload;
      this.payFrom.downloadold = this.clickrow.jbaDownload;
      this.payFrom.discountold = this.clickrow.jbaPackages;
      this.payFrom.startDateold = this.clickrow.jbaStartTime * 1000;
      this.payFrom.endDateold = this.clickrow.jbaState == 1? this.clickrow.jbaExpires * 1000:new Date().getTime()

      this.payFrom.startDate = this.clickrow.jbaStartTime * 1000;

      this.payDia = true;
    },
    //业务办理保存
    paySave() {
      if (this.submitForm(this.payFromRef, this)) {
        this.loading2 = true
        if (
          this.payFrom.businessType == "套餐变更" ||
          this.payFrom.businessType == "宽带续费"
        ) {
          this.$axios({
            url: `/common2/broadbandAccounts/business/${this.clickrow.jbaId}`,
            method: "put",
            data: {
              jbaComment: this.payFrom.businessType,
              notificationSMS:
                this.payFrom.other.indexOf("是否发送信息给客户？") == -1
                  ? "1"
                  : "0",
              jbaStartTime: Math.round(this.payFrom.startDate / 1000).toString(),
              jbaExpires: Math.round(this.payFrom.endDate / 1000).toString(),
              jbaPackages: this.payFrom.planType,
              receivableAmount: this.payFrom.moneyDeceivable, //应收金额
              actualAmount: this.payFrom.receivedMoney, //实际金额
              paymentMethod: this.payFrom.InAndExp, //收款方式
              faId: Number(this.payFrom.accReceivable[2]) //收款账户
            }
          }).then(res => {
            this.$message.success("成功");
            this.payDia = false;
            this.loading2 = false
            this.dbTable(this.clickrow);
            this.getData();
          }).catch(err => {
            this.loading2 = false
            this.isError(err,this)
          });
        } else if (this.payFrom.businessType == "退费销户") {
          this.$axios({
            url: `/common2/broadbandAccounts/business/${this.clickrow.jbaId}`,
            method: "put",
            data: {
              jbaComment: this.payFrom.businessType,
              notificationSMS:
                this.payFrom.other.indexOf("是否发送信息给客户？") == -1
                  ? "1"
                  : "0",
              receivableAmount: this.payFrom.moneyDeceivable, //应收金额
              actualAmount: this.payFrom.receivedMoney, //实际金额
              paymentMethod: this.payFrom.InAndExp, //收款方式
              faId: Number(this.payFrom.accReceivable[2]), //收款账户
              jbaExpires: "0"
            }
          }).then(res => {
            this.$message.success("成功");
            this.payDia = false;
            this.loading2 = false
            this.dbTable(this.clickrow);
            this.getData();
          }).catch(err => {
            this.loading2 = false
            this.isError(err,this)
          });
        } else if (this.payFrom.businessType == "宽带迁移") {
          this.$axios({
            url: `/common2/broadbandAccounts/business/${this.clickrow.jbaId}`,
            method: "put",
            data: {
              jbaComment: this.payFrom.businessType,
              notificationSMS:
                this.payFrom.other.indexOf("是否发送信息给客户？") == -1
                  ? "1"
                  : "0",
              jbaGwid: this.payFrom.device,
              receivableAmount: this.payFrom.moneyDeceivable, //应收金额
              actualAmount: this.payFrom.receivedMoney, //实际金额
              paymentMethod: this.payFrom.InAndExp, //收款方式
              faId: Number(this.payFrom.accReceivable[2]), //收款账户
              jbaExpires: "0"
            }
          }).then(res => {
            this.$message.success("成功");
            this.payDia = false;
            this.loading2 = false
            this.dbTable(this.clickrow);
            this.getData();
          }).catch(err => {
            this.loading2 = false
            this.isError(err,this)
          });
        }
      }
    },
    //业务办理关闭
    payClose() {
      this.payFrom = {
        businessType: "宽带续费",
        businessNumber: "",
        name: "",
        idType: "",
        idNumber: "",
        certificationType: "",

        planTypeold: "",
        uploadold: "",
        downloadold: "",
        discountold: "",
        startDateold: "",
        endDateold: "",

        other: [],

        planType: "",
        upload: "",
        download: "",
        discount: null,
        startDate: new Date(),
        endDate: "",

        moneyDeceivable: "0.00",
        receivedMoney: "0.00",
        InAndExp: "",
        accReceivable: "",

        refund: "普通退费",
        refundDate: new Date(),

        device: "",
        deviceOpt: [],

        moneyT1: "应收金额",
        moneyT2: "实收金额",
        moneyT3: "收款方式",
        moneyT4: "收款账户"
      };
      this.resetForm(this.payFromRef, this)
    },
    //业务类型 改变处理
    async businessChange(val) {
      
      if (val == "套餐变更") {
        let demOpt = [];
        for (const item of this.addFrom.packAgetOpt) {
          if (this.payFrom.discountold != item.sppId) {
            demOpt.push(item);
          }
        }
        this.addFrom.packAgetOpt = demOpt;
      } else {
        this.addFrom.packAgetOpt = await this.getPackagePlan();
      }

      (this.payFrom.other = []), (this.payFrom.planType = "");
      this.payFrom.upload = "";
      this.payFrom.download = "";
      this.payFrom.discount = null;
      this.payFrom.startDate = new Date();
      this.payFrom.endDate = "";

      this.payFrom.moneyDeceivable = "";
      this.payFrom.receivedMoney = "";
      this.payFrom.InAndExp = "";
      this.payFrom.accReceivable = "";

      this.payFrom.refund = "普通退费";
      this.payFrom.refundDate = new Date();

      this.payFrom.device = "";
      this.payFrom.deviceOpt = [];

      if (val == "退费销户") {
        this.refundChange(this.payFrom.refund);
        this.payFrom.moneyT1 = "应退金额";
        this.payFrom.moneyT2 = "实退金额";
        this.payFrom.moneyT3 = "退款方式";
        this.payFrom.moneyT4 = "退款账户";
      } else {
        this.payFrom.moneyT1 = "应收金额";
        this.payFrom.moneyT2 = "实收金额";
        this.payFrom.moneyT3 = "收款方式";
        this.payFrom.moneyT4 = "收款账户";
      }
      if (val == "宽带迁移") {
        let demOpt2 = [];
        for (const item of this.addFrom.deviceOpt) {
          if (this.addFrom.device != item.ikDeviceId) {
            demOpt2.push(item);
          }
        }
        this.payFrom.deviceOpt = demOpt2;
      }
    },
    refundChange(val) {
      console.log(val);
      if (val == "普通退费") {
        let oldAllMoney = this.clickrow.amount;
        let oldDay = DateMinus(
          this.payFrom.startDateold,
          this.payFrom.endDateold
        );
        let laveDay = DateMinus(new Date().getTime(), this.payFrom.endDateold);
        //算应退金额
        let money = Number(((oldAllMoney / oldDay) * laveDay).toFixed(2));
        this.payFrom.moneyDeceivable = money.toString();
        this.payFrom.receivedMoney = money.toString();
      } else if (val == "全额不退") {
        console.log("全额不退");
        
        this.payFrom.moneyDeceivable = "0.00";
        this.payFrom.receivedMoney = "0.00";
      }
      this.resetForm(this.payFromRef, this)
    },
    //修改处理
    async handleModify() {
      this.addFrom.other = ["是否发送账户密码到用户手机？"]
      this.addFrom.title = "修改";
    },
    //停用
    handleDisable() {
      this.loading = true
      this.$axios({
        url: "/common2/broadbandAccounts/usageStatus",
        method: "GET",
        params: {
          jbaIds: this.clickrow.jbaId,
          status: this.clickrow.jbaEnabled == "yes" ? "down" : "up"
        }
      }).then(res => {
        this.$message.success("成功");
        this.addDia = false;
        this.getData();
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    //强制下线
    handleOffline() {
      this.loading = true
      this.$axios({
        url: `/common2/broadbandAccounts/offLine/${this.clickrow.jbaId}`,
        method: "put"
      }).then(res => {
        this.$message.success("成功");
        this.addDia = false;
        this.loading = false
        this.getData();
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    //刷新用户状态
    refresh() {
      this.getOnlineState();
      
    },
    //刷新账户信息
    async refreshAcc(){
      await this.getRefrshAcc()
      this.getData();
    },
    //历史记录
    history() {
      this.getHistory();
      this.hisDia = true;
    },

    //选择数据条数
    sizeChange(callVal, pageSize, getData, ...cleanRow) {
      this.$data[pageSize] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },
    //具体那一页
    currentChange(callVal, currentPage, getData, ...cleanRow) {
      this.$data[currentPage] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },

    //查询首页数据
    getData() {
      this.$axios({
        url: "/common2/broadbandAccounts",
        method: "get",
        params: {
          cursor: this.currentPage,
          limit: this.pageSize,
          jbaEnabled: this.scFrom.status, //用户状态（yes。no）
          jbaExpires: "", //到期时间
          popName: this.scFrom.userName, //用户姓名
          jbaOnlineState: "", //在线状态（在线，不在线
          jbaPackages: "", //当前套餐（0表示自定义
          jbaPppType: this.scFrom.type, //拨号类型（any、pppoe、pptp、l2tp、ovpn、web）
          jbaStartTime: "" //开始时间
        }
      }).then(res => {
        this.tableData = res.data.result.records;
        this.total = res.data.result.total;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查詢方案下拉框
    getPackagePlan() {
      return this.$axios({
        url: "/common2/packagePlans/list",
        method: "get"
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查询设备
    getDev() {
      return this.$axios({
        url: "/common2/companyIkuaiDevices",
        method: "get",
        params: {
          cursor: 1,
          limit: 100
        }
      }).then(res => {
        return res.data.result.records;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查询单条
    getSingle(id) {
      return this.$axios({
        url: `/common2/broadbandAccounts/${id}`,
        method: "get"
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //读取身份证
    startFun() {
      //读取身份证按钮
      new Device().startFun(); //读取身份证触发函数
      console.log(window.massageList.Certificate); //读取身份证返回值
      let studentMsg = window.massageList.Certificate;
      this.addFrom.name = studentMsg.Name;
      this.addFrom.gender = studentMsg.Sex;
      this.addFrom.idType = "身份证/临时身份证/户口本";
      this.addFrom.idNumber = studentMsg.IDNumber;
      this.addFrom.nation = studentMsg.Nation;
      this.addFrom.birthday = `${studentMsg.Birthday.substring(
        0,
        4
      )}年${studentMsg.Birthday.substring(
        4,
        6
      )}月${studentMsg.Birthday.substring(6, 8)}日`;
      this.addFrom.address = studentMsg.Address;
    },
    //获取账户
    // /common1/bankAccounts
    getAccount() {
      this.$axios({
        url: "/common1/bankAccounts/list",
        method: "get"
      }).then(res => {
        console.log(res);
        let data = res.data.result;
        let dataName = [];
        //取出一级菜单
        for (let i = 0; i < data.length; i++) {
          dataName[i] = {};
          dataName[i].value = data[i].faPaymentType;
          dataName[i].label = data[i].faPaymentType;
          dataName[i].children = [];
        }
        //一级菜单去重
        let schoolArr = [];
        let schoolObj = {};
        for (let i = 0; i < dataName.length; i++) {
          if (!schoolObj[dataName[i].label]) {
            schoolArr.push(dataName[i]);
            schoolObj[dataName[i].label] = true;
          }
        }
        dataName = schoolArr;
        //取出二级菜单
        for (let i = 0; i < dataName.length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (dataName[i].value === data[j].faPaymentType) {
              let school = {};
              school.value = data[j].faUserName;
              school.label = data[j].faUserName;
              school.children = [];
              dataName[i].children.push(school);
            }
          }
        }
        //二级菜单去重
        for (let i = 0; i < dataName.length; i++) {
          let departmentArr = [];
          let departmentObj = {};
          for (let j = 0; j < dataName[i].children.length; j++) {
            if (!departmentObj[dataName[i].children[j].label]) {
              departmentArr.push(dataName[i].children[j]);
              departmentObj[dataName[i].children[j].label] = true;
            }
          }
          dataName[i].children = departmentArr;
        }
        //取出三级菜单
        for (let i = 0; i < dataName.length; i++) {
          for (let j = 0; j < dataName[i].children.length; j++) {
            for (let k = 0; k < data.length; k++) {
              if (dataName[i].children[j].value === data[k].faUserName) {
                let department = {};
                department.value = data[k].faId;
                department.label = data[k].faAccount;
                dataName[i].children[j].children.push(department);
              }
            }
          }
        }
        console.log(dataName);
        this.inexList = dataName;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //刷新账户在线状态
    getOnlineState() {
      this.$axios({
        url: "/common2/broadbandAccounts/refresh",
        method: "GET"
      }).then(res => {
        this.$message.success("成功")
        this.getData();
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //刷新账户信息
    getRefrshAcc(){
      this.$axios({
        url: "/common2/broadbandAccounts/account",
        method: "GET"
      }).then(res => {

      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查优惠政策
    getDiscount(id) {
      return this.$axios({
        url: `/common2/favouredPolicys/list`,
        method: "get",
        params: {
          sfpSppId: id,
          cursor: 1,
          limit: 10
        }
      }).then(res => {
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getHistory() {
      this.$axios({
        url: "/common2/eventFollowupRecordss",
        method: "get",
        params: {
          cursor: this.currentPage2,
          limit: this.pageSize2,
          jhfTableType: "网络管理",
          jhfTableId: this.clickrow.jbaId
        }
      }).then(res => {
        this.historyData = res.data.result.records;
        this.total2 = res.data.result.total;
      }).catch(err => {
        this.isError(err,this)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input-group__append {
  padding: 0 7px;
}
.title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
}
</style>